//PERMISSIONS

export enum PERMISSION {
    MEDICAL_WRITE = "MEDICAL_WRITE",
    MEDICAL_READ = "MEDICAL_READ",
    PERSONAL_ACCESS = "PERSONAL_ACCESS",
    BUSINESS_READ = "BUSINESS_READ",
    BUSINESS_WRITE = "BUSINESS_WRITE",
    SHARE_RESEARCHERS = "SHARE_RESEARCHERS",
    EDIT_SUBMISSIONS = "EDIT_SUBMISSIONS",
    EDIT_INVESTIGATION = "EDIT_INVESTIGATION",
    MAKE_DISCOUNTS = "MAKE_DISCOUNTS",
    MANAGE_PHARMACY_CENTRAL = "MANAGE_PHARMACY_CENTRAL",
    MAKE_PHARMACY_REQUESTS = "MAKE_PHARMACY_REQUESTS",
    UPDATE_PHARMACY_CENTRAL = "UPDATE_PHARMACY_CENTRAL",
    ANALYTICS_DEPARTMENT = "ANALYTICS_DEPARTMENT",
    CREATE_APPOINTMENTS = "CREATE_APPOINTMENTS",
    VISUALIZE_APPOINTMENTS = "VISUALIZE_APPOINTMENTS",
    CANCEL_APPOINTMENTS = "CANCEL_APPOINTMENTS",
    EDIT_OUTPATIENTS = "EDIT_OUTPATIENTS",
    EDIT_HOSPITALIZATION = "EDIT_HOSPITALIZATION",
    HOSPITALIZATION = "HOSPITALIZATION",
    VIEW_HOSPITALIZATION = "VIEW_HOSPITALIZATION",
    DISCHARGE_PATIENT = "DISCHARGE_PATIENT",
    UPDATE_LAB_REQUESTS = "UPDATE_LAB_REQUESTS",
    CARE_GIVER = "CARE_GIVER",
    NURSE_FW = "NURSE_FW",
    VIEW_BILLING = "VIEW_BILLING",
    DELETE_RESEARCHER = "DELETE_RESEARCHER",

 }

export const PERMISSIONS_LIST = [PERMISSION.MEDICAL_WRITE, PERMISSION.MEDICAL_READ, PERMISSION.PERSONAL_ACCESS, PERMISSION.BUSINESS_READ, PERMISSION.SHARE_RESEARCHERS, PERMISSION.EDIT_SUBMISSIONS, PERMISSION.MAKE_DISCOUNTS, PERMISSION.MANAGE_PHARMACY_CENTRAL, PERMISSION.MAKE_PHARMACY_REQUESTS, PERMISSION.UPDATE_PHARMACY_CENTRAL, PERMISSION.CREATE_APPOINTMENTS, PERMISSION.CANCEL_APPOINTMENTS, PERMISSION.VISUALIZE_APPOINTMENTS, PERMISSION.ANALYTICS_DEPARTMENT, PERMISSION.EDIT_OUTPATIENTS, PERMISSION.UPDATE_LAB_REQUESTS, PERMISSION.VIEW_BILLING, PERMISSION.DISCHARGE_PATIENT]

//Roles
export const MEDICAL_DIRECTOR = [PERMISSION.MEDICAL_WRITE, PERMISSION.MEDICAL_READ, PERMISSION.PERSONAL_ACCESS, PERMISSION.BUSINESS_READ, PERMISSION.SHARE_RESEARCHERS, PERMISSION.MAKE_DISCOUNTS, PERMISSION.MANAGE_PHARMACY_CENTRAL,PERMISSION.EDIT_INVESTIGATION,  PERMISSION.MAKE_PHARMACY_REQUESTS, PERMISSION.UPDATE_PHARMACY_CENTRAL, PERMISSION.CREATE_APPOINTMENTS, PERMISSION.CANCEL_APPOINTMENTS, PERMISSION.VISUALIZE_APPOINTMENTS, PERMISSION.EDIT_OUTPATIENTS, PERMISSION.EDIT_HOSPITALIZATION, PERMISSION.HOSPITALIZATION, PERMISSION.VIEW_HOSPITALIZATION, PERMISSION.UPDATE_LAB_REQUESTS, PERMISSION.CARE_GIVER, PERMISSION.NURSE_FW, PERMISSION.VIEW_BILLING, PERMISSION.DISCHARGE_PATIENT];
export const STUDENT = [PERMISSION.MEDICAL_READ, PERMISSION.PERSONAL_ACCESS, PERMISSION.BUSINESS_READ];
export const SHERWOOD_STAFF = [PERMISSION.MEDICAL_WRITE, PERMISSION.MEDICAL_READ, PERMISSION.PERSONAL_ACCESS, PERMISSION.BUSINESS_READ, PERMISSION.BUSINESS_WRITE, PERMISSION.SHARE_RESEARCHERS, PERMISSION.EDIT_SUBMISSIONS, PERMISSION.EDIT_INVESTIGATION, PERMISSION.CREATE_APPOINTMENTS, PERMISSION.CANCEL_APPOINTMENTS, PERMISSION.HOSPITALIZATION, PERMISSION.VIEW_HOSPITALIZATION, PERMISSION.CARE_GIVER, PERMISSION.NURSE_FW, PERMISSION.DISCHARGE_PATIENT];
export const DOCTOR = [PERMISSION.MEDICAL_WRITE, PERMISSION.MEDICAL_READ, PERMISSION.PERSONAL_ACCESS,  PERMISSION.EDIT_SUBMISSIONS, PERMISSION.CREATE_APPOINTMENTS, PERMISSION.CANCEL_APPOINTMENTS, PERMISSION.VISUALIZE_APPOINTMENTS, PERMISSION.HOSPITALIZATION, PERMISSION.VIEW_HOSPITALIZATION, PERMISSION.CARE_GIVER, PERMISSION.NURSE_FW, PERMISSION.DISCHARGE_PATIENT];
export const DEPARTMENT_HEAD = [...DOCTOR, PERMISSION.ANALYTICS_DEPARTMENT];
export const PRIVATE_DOCTOR = [PERMISSION.MEDICAL_WRITE, PERMISSION.MEDICAL_READ, PERMISSION.PERSONAL_ACCESS, PERMISSION.BUSINESS_READ, PERMISSION.CREATE_APPOINTMENTS, PERMISSION.CANCEL_APPOINTMENTS, PERMISSION.VISUALIZE_APPOINTMENTS, PERMISSION.HOSPITALIZATION, PERMISSION.VIEW_HOSPITALIZATION, PERMISSION.DISCHARGE_PATIENT];
export const EXECUTIVE_DIRECTOR = [PERMISSION.MEDICAL_WRITE, PERMISSION.MEDICAL_READ, PERMISSION.PERSONAL_ACCESS, PERMISSION.BUSINESS_READ, PERMISSION.SHARE_RESEARCHERS, PERMISSION.BUSINESS_WRITE, PERMISSION.CREATE_APPOINTMENTS, PERMISSION.CANCEL_APPOINTMENTS, PERMISSION.EDIT_INVESTIGATION, PERMISSION.VISUALIZE_APPOINTMENTS, PERMISSION.EDIT_OUTPATIENTS, PERMISSION.EDIT_HOSPITALIZATION, PERMISSION.DISCHARGE_PATIENT];
export const ADMIN = [PERMISSION.MEDICAL_READ, PERMISSION.PERSONAL_ACCESS, PERMISSION.SHARE_RESEARCHERS, PERMISSION.CREATE_APPOINTMENTS, PERMISSION.CANCEL_APPOINTMENTS, PERMISSION.VISUALIZE_APPOINTMENTS];
export const ADMIN_FW = [PERMISSION.MEDICAL_READ, PERMISSION.PERSONAL_ACCESS, PERMISSION.SHARE_RESEARCHERS, PERMISSION.CREATE_APPOINTMENTS, PERMISSION.CANCEL_APPOINTMENTS, PERMISSION.VISUALIZE_APPOINTMENTS, PERMISSION.EDIT_HOSPITALIZATION, PERMISSION.VIEW_HOSPITALIZATION, PERMISSION.HOSPITALIZATION, PERMISSION.DISCHARGE_PATIENT];
export const NURSE = [PERMISSION.MEDICAL_WRITE, PERMISSION.MEDICAL_READ, PERMISSION.PERSONAL_ACCESS, PERMISSION.VIEW_HOSPITALIZATION, PERMISSION.DISCHARGE_PATIENT];
export const NURSE_FW = [PERMISSION.MEDICAL_WRITE, PERMISSION.PERSONAL_ACCESS, PERMISSION.CARE_GIVER, PERMISSION.NURSE_FW, PERMISSION.VIEW_HOSPITALIZATION, PERMISSION.DISCHARGE_PATIENT];
export const NURSE_MANAGER = [PERMISSION.MEDICAL_READ, PERMISSION.MEDICAL_WRITE, PERMISSION.PERSONAL_ACCESS,  PERMISSION.EDIT_SUBMISSIONS, PERMISSION.CREATE_APPOINTMENTS, PERMISSION.CANCEL_APPOINTMENTS, PERMISSION.VISUALIZE_APPOINTMENTS, PERMISSION.VIEW_HOSPITALIZATION, PERMISSION.CARE_GIVER, PERMISSION.NURSE_FW];
export const CARE_GIVER = [PERMISSION.MEDICAL_WRITE, PERMISSION.PERSONAL_ACCESS, PERMISSION.CARE_GIVER, PERMISSION.VIEW_HOSPITALIZATION, PERMISSION.DISCHARGE_PATIENT];
export const BUSINESS_ASSISTANT = [PERMISSION.BUSINESS_READ];
export const LAB_MANAGER = [PERMISSION.PERSONAL_ACCESS, PERMISSION.MEDICAL_WRITE, PERMISSION.MEDICAL_READ, PERMISSION.SHARE_RESEARCHERS, PERMISSION.UPDATE_LAB_REQUESTS];
export const LAB_ASSISTANT = [PERMISSION.MEDICAL_READ, PERMISSION.MEDICAL_WRITE, PERMISSION.UPDATE_LAB_REQUESTS];
export const SOCIAL_ASSISTANT = [PERMISSION.MEDICAL_WRITE, PERMISSION.MEDICAL_READ, PERMISSION.PERSONAL_ACCESS, PERMISSION.MAKE_DISCOUNTS];
export const PHARMACY_MANAGER = [PERMISSION.MEDICAL_READ, PERMISSION.PERSONAL_ACCESS, PERMISSION.MANAGE_PHARMACY_CENTRAL, PERMISSION.UPDATE_PHARMACY_CENTRAL];
export const PHARMACY_ASSISTANT = [PERMISSION.MEDICAL_READ, PERMISSION.PERSONAL_ACCESS, PERMISSION.UPDATE_PHARMACY_CENTRAL];
export const MAJOR = [...DEPARTMENT_HEAD, PERMISSION.MAKE_PHARMACY_REQUESTS];
export const PHARMACY_CENTRAL_PERMISSIONS = [PERMISSION.MANAGE_PHARMACY_CENTRAL, PERMISSION.UPDATE_PHARMACY_CENTRAL];
export const PHARMACY_RELATED_PERMISSIONS = [...PHARMACY_CENTRAL_PERMISSIONS, PERMISSION.MAKE_PHARMACY_REQUESTS];
export const USER_ROLES = { "PRIVATE_DOCTOR": PRIVATE_DOCTOR, "NURSE": NURSE, "NURSE_FW" : NURSE_FW, "NURSE_MANAGER" : NURSE_MANAGER, "CARE_GIVER" : CARE_GIVER, "ADMIN": ADMIN, "STUDENT": STUDENT, 
                            "MEDICAL_DIRECTOR": MEDICAL_DIRECTOR, "DOCTOR": DOCTOR, "EXECUTIVE_DIRECTOR": EXECUTIVE_DIRECTOR, "BUSINESS_ASSISTANT": BUSINESS_ASSISTANT, 
                            "LAB_MANAGER": LAB_MANAGER, "LAB_ASSISTANT": LAB_ASSISTANT, "SOCIAL": SOCIAL_ASSISTANT, "PHARMACY_MANAGER": PHARMACY_MANAGER, 
                            "PHARMACY_ASSISTANT": PHARMACY_ASSISTANT, "MAJOR": MAJOR, "DEPARTMENT_HEAD" : DEPARTMENT_HEAD, "ADMIN_FW" : ADMIN_FW };
export const ALL_ROLES = { ...USER_ROLES, "SHERWOOD_STAFF": SHERWOOD_STAFF }